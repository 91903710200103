import Typography from '@mui/material/Typography';
import { currentUser } from 'authentication/redux/selectors/userSelector';
import LuminaButton from 'core/components/Button';
import useLazyAuthTracking from 'core/hooks/useLazyAuthTracking';
import useTracking from 'core/hooks/useTracking';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

export default function Home(): JSX.Element {
    const router = useRouter();
    const track = useTracking('CORE');
    const { event } = useLazyAuthTracking('CORE');
    const user = useSelector(currentUser);

    useEffect(() => {
        if (user.phone_number !== null) {
            event('Encounters Not Found Page');
        } else {
            track.event('Encounters Not Found Page');
        }
    }, [user]);

    const onBack = (): void => {
        if (user.phone_number !== null) {
            event('Clicks Back on Not Found Page');
        } else {
            track.event('Clicks back on Not Found Page');
        }
        router.push('/');
    };
    return (
        <div className="w-screen h-screen flex flex-col justify-center items-center p-6 text-center">
            <Typography variant="24-medium">
                Mohon maaf, halaman yang Anda cari tidak ditemukan
            </Typography>
            <div className="my-4">
                <LuminaButton onClick={onBack}>Kembali</LuminaButton>
            </div>
        </div>
    );
}
